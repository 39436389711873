import { Tooltip as KTooltip } from "@kobalte/core";
import type { ComponentProps, JSXElement, ParentComponent } from "solid-js";
import { twMerge } from "tailwind-merge";
import { type ComponentTheme, getThemeClasses } from "@core/lib/theme";

export const Tooltip: ParentComponent<{
  content: JSXElement;
  theme: ComponentTheme;
  placement: ComponentProps<typeof KTooltip.Root>["placement"];
  ignoreSafeArea?: boolean;
  mount?: HTMLElement;
}> = (props) => {
  /**
   *{
    "light": [
        "bg-slate-100",
        "border-slate-200",
        "text-slate-900"
    ],
    "dark": [
        "bg-slate-950",
        "border-slate-900",
        "text-white"
    ],
    "default": [
        "bg-slate-100",
        "border-slate-200",
        "text-slate-900",
        "dark:bg-slate-950",
        "dark:border-slate-900",
        "dark:text-white"
    ],
    "invert": [
        "bg-slate-950",
        "border-slate-900",
        "text-white",
        "dark:bg-slate-100",
        "dark:border-slate-200",
        "dark:text-slate-900"
    ]
}
   */
  const theme = getThemeClasses({
    light: ["bg-slate-100", "border-slate-200", "text-slate-900"],
    dark: ["bg-slate-950", "border-slate-900", "text-white"],
  });

  return (
    <KTooltip.Root placement={props.placement} openDelay={200} ignoreSafeArea={props.ignoreSafeArea}>
      {props.children}
      <KTooltip.Portal mount={props.mount}>
        <KTooltip.Content class={twMerge("px-4 py-2 border rounded text-xs z-20", theme[props.theme])}>
          <KTooltip.Arrow />
          {props.content}
        </KTooltip.Content>
      </KTooltip.Portal>
    </KTooltip.Root>
  );
};
export const TooltipTrigger: ParentComponent<ComponentProps<typeof KTooltip.Trigger>> = (props) => (
  <KTooltip.Trigger {...props}>{props.children}</KTooltip.Trigger>
);
